<template>
    <div>
        <v-form ref="form" class="p-2" :id="version">
        <v-row dense>
            <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                <v-autocomplete
                    v-if="!new_district"
                    v-model="item.distrito"
                    :items="places"
                    outlined
                    dense
                    item-text="distrito_cidade_estado"
                    item-value="all"
                    :class="$css_field"
                    :rules="[rules.required]"
                    :filter="filterData"
                >
                    <template v-slot:label>
                        <span :class="$css_field">Distrito</span>
                    </template>
                </v-autocomplete>

                <span v-else>
                    <v-row dense>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field
                                v-model="new_item.bairro"
                                :rules="[rules.required]"
                                outlined
                                dense
                                :class="$css_field"
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Novo distrito</span>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <v-text-field
                                v-model="new_item.cidade"
                                :rules="[rules.required]"
                                outlined
                                dense
                                :class="$css_field"
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Cidade</span>
                                </template> 
                            </v-text-field>
                        </v-col>

                        <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                            <v-text-field
                                v-model="new_item.estado"
                                :rules="[rules.required]"
                                outlined
                                dense
                                :class="$css_field"
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Estado</span>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </span>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="">
                <v-btn
                    color="primary"
                    @click="validate"
                    :class="$css_button"
                >
                    <span v-if="item.id">Editar</span>
                    <span v-else>Adicionar</span>
                </v-btn>

                <span class="ml-3"></span>
                <v-btn
                    dark
                    color="purple"
                    :class="$css_button"
                    @click="newDistrict"
                >
                    <span v-if="!new_district">Novo distrito</span>
                    <span v-else>voltar</span>
                </v-btn>
            </v-col>
        </v-row>
        </v-form> 
        <!-- List  --> 
        <v-row v-if="object.items" class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page="10"
                        sort-by="id"
                        class="elevation-1 table-striped"
                        :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                    >
                        <template v-slot:[`item.distrito`]="{ item }">
                            <span :class="$css_report_list">{{ item.distrito }}</span>
                        </template>

                        <template v-slot:[`item.cidade`]="{ item }">
                            <span :class="$css_report_list">{{ item.cidade }}</span>
                        </template>

                        <template v-slot:[`item.estado`]="{ item }">
                            <span :class="$css_report_list">{{ item.estado }}</span>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-card-actions>
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="destroy(item)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Excluir</span>
                                </v-tooltip>
                            </v-card-actions>     
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>


    </div>
</template>

<script>

export default {
    created() {
        this.setItems()
        this.loadPlaces()
    },

    components: {
   
    },

    props: {
        object: {
            require: true,
        },
    },

    computed: {
        placeitems() {
            return this.$store.state.placeitems.items.data    
        },

        places() {
            return this.$store.state.places.items.data    
        },
    }, 

    data() {
        return {
            version: '1.1.1',
            new_district: false,
            new_item: {
                bairro: '',
                cidade: '',
                estado: ''
            },
            item: {},
            params: {},
            rules: {
                required: value => !!value || 'Obrigatório',
            },
            headers: [
                { text: 'DISTRITO', align: 'start', value: 'distrito', },
                { text: 'CIDADE', align: 'start', value: 'cidade', },
                { text: 'ESTADO', align: 'start', value: 'estado_sigla', },
                { text: 'AÇĀO', value: 'actions', sortable: false },
            ],

            place_params: {
                distinct: 'true',
                paginate: 'false',
            },

            items: []
        };

        /*
        cidade: "São Paulo"
        distrito: "Tatuapé"
        estado_nome: "São Paulo"
        estado_sigla:"SP"
        item_id: "89ced88a-381a-4c87-b16e-64388b177ab3"
        mesorregiao: "Metropolitana de São Paulo"
        model: "properties"
        pais: "Brasil"
        regiao_nome: "Sudeste"
        regiao_sigla: "SE"
        */
    },

    methods:{
        setItems(){
            this.items = this.object.items
        },

        loadPlaceItems(){
            if(this.object.cidade && this.object.cidade != undefined){
                this.params.cidade = this.object.cidade
            }
            if(this.object.estado && this.object.estado != undefined){
                this.params.estado = this.object.estado
            }

            this.$store.dispatch('loadPlaceItems', this.params) 
        },

        loadPlaces(){
            
            if(this.object.cidade && this.object.cidade != undefined){
                this.place_params.cidade = this.object.cidade
            }
            if(this.object.estado && this.object.estado != undefined){
                this.place_params.estado = this.object.estado
            }
            this.$store.dispatch('loadPlaces', this.place_params) 
        },

        newDistrict(){
            this.new_district = !this.new_district
            this.setNewDistrict()
        },

        setNewDistrict(){
            if(this.new_district){
                this.item.distrito = ''
                this.new_item.cidade = this.object.cidade
                this.new_item.estado = this.object.estado
            }else{
                this.new_item.cidade = ''
                this.new_item.estado = ''
            }
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.store(this.item)
            }
        },

        store(item){
            if(this.new_district){
                item.distrito = this.new_item.bairro
                item.cidade = this.new_item.cidade
                item.estado_sigla = this.new_item.estado
                item.estado_nome = 'NA'
                item.regiao_sigla = 'NA'
                item.regiao_nome = 'NA'
                item.mesorregiao = 'NA'
                item.pais = 'Brasil' 
            }else{
                var all = item.distrito.split(',')
                item.distrito = all[0]
                item.cidade = all[1]
                item.estado_sigla = all[2]
                item.estado_nome = all[3]
                item.regiao_sigla = all[4]
                item.regiao_nome = all[5]
                item.mesorregiao = all[6] 
                item.pais = all[7] 
            }
           
            item.model = this.object.model
            item.item_id = this.object.item_id

            const action = 'storePlace' 
            this.$store.dispatch(action, item)
                        .then(response => {
                            this.items.push(response.data)
                            this.new_district = false
                            this.setNewDistrict()
                            this.$snotify.success('Cadastro com sucesso!')
                            
                            //this.$emit('callLoadItem', this.object.item_id)
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
                   
        },
        
        destroy(item){
            this.$store.dispatch('destroyPlace', item.id)
                        .then(response => {
                            this.items.splice(this.items.indexOf(item), 1)
                            //this.$emit('callLoadItem',this.object.item_id)
                        })
                        .catch(error => {
                            this.$snotify.error('Erro no cadastro')
                            this.errors = error.response.data.errors
                        })
                        .finally(
                            //this.$snotify.remove()
                            this.$snotify.success('Excluido com sucesso')
                        )
        },
    }
}
</script>