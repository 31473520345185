<template>
    <div>
        <alert-component v-if="object.items && object.items.length > object.limit && object.model == 'shopwindow'" :alert="alerts.limit"></alert-component>
      
        <v-form v-if="(object.items && object.items.length <= object.limit) || object.model != 'shopwindow'" ref="form" class="p-2">
           <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.codigo"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                        :counter="7"
                        maxlength="7"
                        autofocus
                        @keydown.enter="validate"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Código</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-btn
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                        <span>Adicionar</span>
                    </v-btn>
                </v-col>  
            </v-row>
        </v-form> 
        <v-row v-if="object.items" class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-data-table
                        :hide-default-header="$vuetify.breakpoint.mobile"
                        :headers="headers"
                        :items="object.items"
                        :items-per-page="10"
                        sort-by="id"
                        class="elevation-1 table-striped"
                        :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                    >
                        <template v-slot:[`item.photo`]="{ item }">
                            <v-avatar
                                class="ma-2"
                                color="#BDBDBD"
                                tile
                                width="200px"
                                height="150px"
                            >
                                <v-img 
                                    @click.prevent="showCarousel(item)"
                                    v-if="setFirstPhoto(item.property_photos, item.condominium_photos) && setFirstPhoto(item.property_photos, item.condominium_photos).length > 0"
                                    :src="setFirstPhoto(item.property_photos, item.condominium_photos)[0].urlremoto"
                                    :lazy-src="setFirstPhoto(item.property_photos, item.condominium_photos)[0].urlremoto"
                                >
                                    <template v-slot:placeholder>
                                        <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                        >
                                        <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5"
                                        ></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                                <v-icon v-else large dark>
                                    mdi-camera 
                                </v-icon>
                            </v-avatar>
                        </template>

                        <template v-slot:[`item.description`]="{ item }">
                           <div class="mb-2 mt-2">
                                <span>
                                    <v-chip
                                        label
                                        small
                                        color="blue lighten-5" 
                                    >
                                    <span style="font-size:12px">{{ item.codigo }}</span> 
                                    </v-chip>   
                                </span>
                                <span class="ml-2">
                                    <v-chip
                                        :color="item.status | statusProperty"
                                        label
                                        small
                                    >
                                        {{ item.status }}
                                    </v-chip> 
                                </span>
                                
                                <span class="ml-2 black--text font-weight-medium">
                                    {{ item.tipo_imovel }}
                                    <span v-if="item.sub_tipo_imovel"> ({{ item.sub_tipo_imovel }})</span>
                                </span>
                            </div>
                            <div>
                                {{ item.endereco }}
                                <span v-if="item.numero">, {{ item.numero }} </span>
                                <span v-if="item.apartamento"> - Apto: {{ item.apartamento }} </span>
                                <span v-if="item.andar">  - {{ item.andar }} andar </span>
                                <span v-if="item.tower && item.tower.nome != ''"> - Torre: {{ item.tower.nome }} </span>
                                <span v-if="item.complemento"> - Compl: {{ item.complemento }} </span>
                                <span v-if="item.bairro">  - {{ item.bairro }} </span>
                                <span v-if="item.cidade">  - {{ item.cidade }} </span>
                                <span v-if="item.estado">  - {{ item.estado }} </span>
                                <span v-if="item.cep">  - {{ item.cep | VMask('#####-###') }} </span>
                            </div> 
                            <div class="mt-3">
                                <span v-if="item.area">
                                    <v-chip
                                        label
                                        small
                                        color="blue-grey lighten-5 ma-1" 
                                    >
                                    <span>Área {{ item.area }} m²</span> 
                                    </v-chip>
                                </span>
                                <span class="ml-2" v-if="item.dormitorios">
                                    <v-chip
                                        label
                                        small
                                        color="blue-grey lighten-5 ma-1" 
                                    >
                                    <span>{{ item.dormitorios }} dorm(s)</span> 
                                    </v-chip>
                                </span>
                                <span class="ml-2" v-if="item.banheiros">
                                    <v-chip
                                        label
                                        small
                                        color="blue-grey lighten-5 ma-1" 
                                    >
                                    <span>{{ item.banheiros }} banheiro(s)</span> 
                                    </v-chip>
                                </span>
                                <span class="ml-2" v-if="item.vagas">
                                    <v-chip
                                        label
                                        small
                                        color="blue-grey lighten-5 ma-1" 
                                    >
                                    <span>{{ item.vagas }} vaga(s)</span> 
                                    </v-chip>
                                </span>
                            </div>

                            <div class="mt-3">
                                <v-row dense>
                                    <v-col v-if="item.valor_venda && item.valor_venda > 0" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <span :class="$css_sub_value">Venda {{ item.valor_venda | toCurrency }}</span> 
                                    </v-col>
                                    <v-col v-if="item.valor_locacao && item.valor_locacao > 0" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <span :class="$css_sub_value">Locaçāo {{ item.valor_locacao | toCurrency }}</span> 
                                    </v-col>
                                    <v-col class="pa-3" v-if="item.valor_condominio && item.valor_condominio > 0" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <span :class="$css_sub_value">Condomínio {{ item.valor_condominio | toCurrency }}</span> 
                                    </v-col>
                                </v-row>
                            </div>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-card-actions>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="showItem(item.id)" class="ml-2">
                                            <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Visualizar</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="destroy(item)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Excluir</span>
                                </v-tooltip>
                            </v-card-actions>     
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row> 


        <!-- Dialog -->
        <v-dialog 
            v-if="property && property != ''"
            v-model="dialog.show" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    <span v-if="property.codigo">Código {{property.codigo}} - {{ property.tipo_imovel }} <span v-if="property.sub_tipo_imovel"> ({{ property.sub_tipo_imovel }})</span> </span>
                    <v-spacer></v-spacer>
                    
                    <button-share-component class="mr-4" :object="property"></button-share-component>
                    
                    <v-btn icon @click="openCloseDialog('show')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <show-component :object="property" :permissions="permissions"></show-component>   
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->

    </div>
</template>

<script>
import ShowComponent from '@/components/properties/show/ShowComponent.vue'
import ButtonShareComponent from '@/components/button_share/ButtonShareComponent.vue'
import AlertComponent from '@/components/alert/AlertComponent.vue'

export default {
    components: {
        ShowComponent,
        ButtonShareComponent,
        AlertComponent
    },

    created() {
        this.setUserPermission()
        //this.checkLimit()
    },

    props: {
        object: {
            require: true,
        },
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },
    }, 

    data () {
        return {
            alerts: {
                limit: {
                    type: 'error',
                    icon: 'mdi-alert-outline',
                    messages: ['Você atingiu o limite de imóveis para essa vitrine. Voce poderá cadastrar imoveis em outra vitrine se desejar. Procure evitar de acrescentar muitas informações na pagina inicial para nāo comprometer a rapidez do site. ']
                },
            },

            register: true,

            permissions: {
                store_property: true,
                active_property: true,
                update_active_property: true,
                view_owners_others: true,
                colaborator: true
            },

            property: {},

            dialog: {
                show: false
            },

            item: {},

            rules: {
                required: value => !!value || 'Required.',
            },

            headers: [
                { text: 'FOTO', align: 'start', value: 'photo', },
                { text: 'DESCRIÇĀO', align: 'start', value: 'description', },
                { text: 'AÇĀO', value: 'actions', sortable: false },
            ],
        }
    },

    methods:{   
        showItem (id) {    
            this.$store.dispatch('loadProperty', id) 
                        .then(response => {
                            this.property = response.data
                            this.dialog.show = true
                            this.checkColaborator()
                        })
                                   
        },
        
        validate () {
            if (this.$refs.form.validate()) {       
                this.store()
            }
        },
        
        store(){
            this.item.model = this.object.model
            this.item.item_id = this.object.item_id
            const action = 'storePropertyRelation'
            this.$store.dispatch(action, this.item)
                        .then(response => {
                            this.$emit('callLoadItem',this.object.item_id)
                            this.$snotify.success('Registered Successfully!')
                            this.reset()
                            this.checkLimit()
                        })
                        .catch(error => {   
                            this.$snotify.error('Erro no cadastro')
                            this.errors = error.response.data.errors
                        })
        },

        reset(){
            this.item = {}
            this.$refs.form.reset()
        },

        destroy(item){
            this.$store.dispatch('destroyPropertyRelation', item.property_relation_id)
                        .then(response => {
                            this.$emit('callLoadItem',this.object.item_id)
                        })
                        .catch(error => {
                            this.$snotify.error('Erro no cadastro')
                            this.errors = error.response.data.errors
                        })
                        .finally(
                            //this.$snotify.remove()
                            this.$snotify.success('Successfully deleted')
                        )
        },

        loadUsers(){
            this.$store.dispatch('loadUsers')
        },

        openCloseDialog(window){
            if(window == 'show'){
                this.dialog.show = !this.dialog.show 
            }
        },

        setUserPermission(){
            this.permissions.store_property = this.userPermission('Cadastra imóvel')
            this.permissions.active_property = this.userPermission('Ativa imóvel da carteira')
            this.permissions.update_active_property = this.userPermission('Edita imóvel ativo da carteira')
            this.permissions.view_owners_others = this.userPermission('Acesso aos proprietarios de outras carteiras')
        },

        checkColaborator(){
            if(this.me.profile != 'Master' && this.me.profile != 'Gerente'){
                if(this.property && this.property.colaborators.length > 0){
                    var access = false
                    this.property.colaborators.forEach(i => {
                        if(i.user_id == this.me.id){
                            access = true
                        }
                    })
                    this.permissions.colaborator = access
                }
            }
        },

        checkLimit(){
            if(this.object.limit && this.object.limit <= this.object.items.length){
                this.register = false
            }
        },

        setFirstPhoto(property_photos, condominium_photos){
            var photo = []
            if(property_photos && property_photos != ''){
                photo = property_photos
            }else{
                if(condominium_photos && condominium_photos != ''){
                    photo = condominium_photos
                }
            }
            return photo
        }
    }    
}
</script>