import axios from "axios"
import { URL_BASE } from '../../../config/config'

const RESOURCE = 'builders'


export default {
    state: {
        items: {
            data: []
        },

        store: false
    },

    mutations: {
        LOAD_BUILDERS (state, data) {
            state.items = data
        },

        STORE_BUILDER (state, status) {
            state.store = status
        }
    },

    actions: {
        async loadBuilders({ commit }, params) {
            try {
                commit('PRELOADER', true); // Inicia o estado de carregamento
                const response = await axios.get(`${URL_BASE}${RESOURCE}`, { params }); // Faz a requisição
                commit('LOAD_BUILDERS', response.data); // Atualiza o estado Vuex com os dados da API
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    window.location.href = "login"; // Redireciona para login se não autorizado
                } else {
                    console.error('Erro ao carregar builders:', error);
                }
            } finally {
                commit('PRELOADER', false); // Finaliza o estado de carregamento
            }
        },

        /* 
        loadBuilders(context, params) {
            context.commit('PRELOADER', true)
            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then( response =>
                        {
                            context.commit('LOAD_BUILDERS', response.data)
                        }
                )
                //.catch( errors => console.log(errors))
                .catch((error) => {
                    if(error.response && error.response.status === 401) {
                        window.location.href = "login";
                    }

                })
                .finally(() => context.commit('PRELOADER', false))
        },
        */

        loadPromisseBuilders(context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${RESOURCE}`, {params})
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        storeBuilder(context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve) => {
                axios.post(`${URL_BASE}${RESOURCE}`, params)
                    .then(response => resolve(response.data))
                    .catch(error => console.log(error))
                    .finally(() => context.commit('PRELOADER', false))
            })
        },
        
        updateBuilder(context, params){
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.put(`${URL_BASE}${RESOURCE}/${params.id}`, params)
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        loadBuilder(context, id) {
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        destroyBuilder(context, id){
            
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.delete(`${URL_BASE}${RESOURCE}/${id}`)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
                    //.finally(() => context.commit('PRELOADER', false))    
            })
            
        },

        trash(context) {
            context.commit('PRELOADER', true)
            axios.get(`${URL_BASE}trash`, { responseType: 'blob' })
                    .then(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'builders.pdf');
                        document.body.appendChild(link);
                        link.click();
                    })
                    .finally(() => context.commit('PRELOADER', false))
        },

        loadPdfBuilders(context, params) {
            context.commit('PRELOADER', true)
            axios.get(`${URL_BASE}${RESOURCE}`,{
                    responseType: "blob",
                    params: params 
                })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'builders.pdf');
                    document.body.appendChild(link);
                    link.click();
                })
                .finally(() => context.commit('PRELOADER', false))
        },

        exportProductProjects(context, params) {         
            return new Promise((reject) => {
                axios.get(`${URL_BASE}export_product_projects`, {
                    responseType: "blob",
                    params: params 
                  })
                    .then(response => {
                        const url = URL.createObjectURL(
                            new Blob([response.data], {
                                type: "application/vnd.ms-excel"
                            })
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "lars_projects.xls");
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))    
            }) 
        },

    },

    getters: {

    }

}