<template>
    <div>
        <v-row v-if="object.data && object.data.length > 0" class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"> 
                <span class="text-h6 mt-6">Histórico de mensalidades</span> <span>(12 últimas)</span>
                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    FATURA
                                </th>
                                <th class="text-left">
                                    STATUS
                                </th>
                                 <th class="text-left">
                                    VALOR R$
                                </th>
                                <th class="text-left">
                                    DATA VENCIMENTO
                                </th>
                                <th class="text-left">
                                    DATA PAGAMENTO
                                </th>
                                <th class="text-left">
                                    AÇÕES
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="itm in object.data"
                                    :key="itm.id"
                                >
                                <td>
                                    <v-chip
                                        label
                                        small
                                        class="overline"
                                    >
                                        {{ itm.codigo }}
                                    </v-chip>
                                </td>
                                <td>
                                    <v-chip
                                        :color="statusDueDate(itm).color"
                                        label
                                        small
                                        class="overline"
                                    >
                                        {{ statusDueDate(itm).description }}
                                    </v-chip>
                                </td>
                                <td>
                                    <span class="overline">R$ {{ setTotal(itm.json_account_values) }}</span>
                                    <v-menu
                                        open-on-click
                                        top
                                        offset-y
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon  
                                                v-bind="attrs"
                                                v-on="on"
                                                class="ml-1"
                                                color="red"
                                            >
                                                mdi-information-variant-circle-outline
                                            </v-icon>
                                        </template>
                
                                        <v-simple-table>
                                            <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        DESCRIÇÃO
                                                    </th>
                                                    <th class="text-left">
                                                        R$
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="it in itm.json_account_values" :key="it.id">
                                                    <td>{{ it.description }}</td>
                                                    <td>R$ {{ it.value }}</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2" class="text-center font-weight-bold">{{ itm.nome }}</td>
                                                </tr>
                                            </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-menu>
                                </td>
                                <td class="overline">{{ formatDate(itm.due_date) }}</td>
                                <td class="overline">{{ formatDate(itm.payment_date) }}</td>
                                <td>
                                    <span v-if="itm.bank_slips && itm.bank_slips.length > 0">
                                        <v-btn
                                            dark
                                            color="green"
                                            :class="$css_button"
                                            target="blank"
                                            :href="itm.bank_slips[0].linkBoleto"
                                            outlined
                                        >
                                            Baixar boleto
                                        </v-btn>
                                    </span>

                                    <span v-else>
                                        <v-btn
                                            v-if="itm.payment_date == null"
                                            dark
                                            color="purple"
                                            :class="$css_button"
                                            outlined
                                            @click="storeBankSlip(itm.id)"
                                        >
                                            Gerar boleto
                                        </v-btn>
                                    </span>
                                </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>    
            </v-col>
        </v-row>  

        <v-dialog
            v-model="dialog.bank_slip_delay"
            transition="dialog-bottom-transition"
            max-width="600"
        >
                <v-card>
                    <v-toolbar
                        color="primary"
                        dark
                    >   
                        Instrução de pagamento
                    </v-toolbar>
                    <v-card-text>
                        <div class="text-h4 pa-12">
                            Aguarde até 2 horas para pagar esse titulo.
                        </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                    <v-btn
                        text
                        @click="dialog.bank_slip_delay = false"
                    >Fechar</v-btn>
                    </v-card-actions>
                </v-card>
        </v-dialog>


    </div>
</template>

<script>
    export default {
        props: {
            object: {
                require: true,
            },
        },

        computed: {
            tenant() {
                return this.$store.state.tenants.items.data    
            }
        },

        data () {
            return {
                dialog: {
                    bank_slip_delay: false
                },

                headers: [
                    { text: 'CODIGO', align: 'start', value: 'codigo', },
                    { text: 'VALOR', align: 'start', value: 'valor' },
                    { text: 'STATUS', align: 'start', value: 'status' },  
                    { text: 'DATA VENCIMENTO', align: 'start', value: 'due_date' },
                    { text: 'DATA PAGAMENTO', align: 'start', value: 'payment_date' }            
                ],
            }
        },

        methods:{   
            setTotal(json){
                let total = 0
                if(json && json.length > 0){
                    json.forEach(model => {
                        if(model.type == 'Crédito'){
                            total += model.value 
                        }
                    })
                }
                return total
            },

            storeBankSlip(id){
                const data = {}
                data.id = id
                this.$store.dispatch('storeBankSlip', data)
                        .then(response => {
                            const bankslip = response.data   
                            if(this.tenant[0].payment_method != 'Boleto Cora'){
                                this.dialog.bank_slip_delay = true
                            }
                            this.$snotify.success('Boleto gerado com sucesso!')
                            this.$emit('callLoadAccounts')
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })

            }
        }    
    }
</script>