import axios from "axios"
import { URL_BASE } from '../../../config/config'

const RESOURCE = 'propertyrelations'


export default {
    actions: {
        storePropertyRelation(context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve) => {
                axios.post(`${URL_BASE}${RESOURCE}`, params)
                    .then(response => resolve(response.data))
                    .catch(error => console.log(error))
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        destroyPropertyRelation(context, id){
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.delete(`${URL_BASE}${RESOURCE}/${id}`)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
                    //.finally(() => context.commit('PRELOADER', false))    
            }) 
        },

        loadPropertyRelations(context, id) {
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}propertyrelation/${id}`)
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },    
        

    },

    getters: {

    }

}