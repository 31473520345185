<template>
    <div>
        <v-form ref="form" class="p-2">
           <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
                    <v-text-field
                        v-model="item.title"
                        :rules="[rules.required]"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Título</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.type"
                        :items="items.type"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Tipo</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.order"
                        label="Número"
                        :rules="[rules.required]"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Ordem</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-btn
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                        <span v-if="item.id">Editar</span>
                        <span v-else>Adicionar</span>
                    </v-btn>
                </v-col>  
           </v-row>
        </v-form> 
        <v-row v-if="object.items" class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-data-table
                        :headers="headers"
                        :items="object.items"
                        :items-per-page="10"
                        sort-by="id"
                        class="elevation-1 table-striped"
                        :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                    >
                        <template v-slot:[`item.description`]="{ item }">
                            <span :class="$css_report_list">{{ item.description }}</span>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-card-actions>
                                
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="editItemContent(item)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-note-edit-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="editItem(item.id)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="destroy(item)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Excluir</span>
                                </v-tooltip>
                            </v-card-actions>     
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row> 

        <!-- Dialog -->
        <v-dialog 
            v-model="dialog.property_relations" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    Lista de Imoveis
                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseDialog('property_relations')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <property-relation-component :object="property_relations" @callLoadItem="loadItem" ref="property_relations"></property-relation-component>
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->    


    </div>
</template>

<script>
import { TYPE_ITEMS } from './config_shop_windows/config_shop_windows.js'
import PropertyRelationComponent from '@/components/property_relations/PropertyRelationComponent.vue'

export default {
    components: {
        PropertyRelationComponent        
    },

    props: {
        object: {
            require: true,
        },
    },

    data () {
        return {
            dialog: {
                property_relations: false
            },

            item: {
                branch_id: '',
                title: '',
                type: '',
                order: ''
            },

            items: {
                type: TYPE_ITEMS
            },

            rules: {
                required: value => !!value || 'Required.',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },

            headers: [
                { text: 'ORDEM',  align: 'start', value: 'order', sortable: 'true' },
                { text: 'TITULO',  value: 'title', },
                { text: 'TIPO',  value: 'type', },
                { text: 'AÇĀO', value: 'actions', sortable: false },
            ],

            property_relations: {
                model: "shopwindow",
                item_id: "",
                items: [],
                limit: 6   
            },
        }
    },

    methods:{   
        loadShopWindows(){
            console.log('loadShopWindows')
        },

        validate () {
            if (this.$refs.form.validate()) {       
                this.storeUpdate()
            }
        },
        
        storeUpdate(){
            this.item.branch_id = this.object.branch_id
            
            const action = this.item.id ? 'updateShopWindow' : 'storeShopWindow'
            this.$store.dispatch(action, this.item)
                        .then(response => {
                            this.$emit('callLoadPromisseShopWindow')
                            this.$snotify.success('Cadastrado com sucesso!')
                            this.reset()
                        })
                        .catch(error => {   
                            this.$snotify.error('Erro no cadastro')
                            this.errors = error.response.data.errors
                        })
        },

        editItem (id) {    
            this.$store.dispatch('loadShopWindow', id) 
                        .then(response => {
                            this.item = response.data
                        })
        },

        loadItem(id){
            this.$store.dispatch('loadShopWindow', id) 
                        .then(response => {
                            const result = response.data
                            this.property_relations.item_id = result.id
                            this.property_relations.items = result.property_relations
                        })
        },

        editItemContent(item){
            if(item.type == 'Lista de imóveis'){
                this.editPropertyRelation(item)
            }
        },

        editPropertyRelation(item){
            this.property_relations.item_id = item.id
            this.property_relations.items = item.property_relations
            this.openCloseDialog('property_relations')
        },

        openCloseDialog(item){
            if(item == 'property_relations'){
                this.dialog.property_relations = !this.dialog.property_relations
            }
        },

        reset(){
            this.item = {}
            this.$refs.form.reset()
        },

        destroy(item){
            this.$store.dispatch('destroyShopWindow', item.id)
                        .then(response => {
                            this.$emit('callLoadPromisseShopWindow')
                        })
                        .catch(error => {
                            this.$snotify.error('Erro no cadastro')
                            this.errors = error.response.data.errors
                        })
                        .finally(
                            //this.$snotify.remove()
                            this.$snotify.success('Excluído com sucesso')
                        )
        },

        actionEmitLinks(action, value){
            this.$emit(action,value)
        },
        
    }    
}
</script>