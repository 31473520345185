import axios from "axios"
import { URL_BASE } from '../../../config/config'

const RESOURCE = 'condominiums'


export default {
    state: {
        items: {
            data: []
        },

        store: false
    },
    
    mutations: {
        LOAD_CONDOMINIUMS (state, data) {
            state.items = data
        },

        STORE_CONDOMINIUM (state, status) {
            state.store = status
        }
    },

    actions: {
        async loadCondominiums({ commit }, params) {
            try {
                commit('PRELOADER', true); // Inicia o estado de carregamento
                const response = await axios.get(`${URL_BASE}${RESOURCE}`, { params }); // Faz a requisição
                commit('LOAD_CONDOMINIUMS', response.data); // Atualiza o estado Vuex com os dados da API
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    window.location.href = "login"; // Redireciona para login se não autorizado
                } else {
                    console.error('Erro ao carregar condos:', error);
                }
            } finally {
                commit('PRELOADER', false); // Finaliza o estado de carregamento
            }
        },

        /* 
        loadCondominiums(context, params) {
            context.commit('PRELOADER', true)
            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then( response =>
                        {
                            context.commit('LOAD_CONDOMINIUMS', response.data)
                        }
                )
                //.catch( errors => console.log(errors))
                .catch((error) => {
                    if(error.response && error.response.status === 401) {
                        window.location.href = "login";
                    }

                })
                .finally(() => context.commit('PRELOADER', false))
        },
        */


        storeCondominium(context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve) => {
                axios.post(`${URL_BASE}${RESOURCE}`, params)
                    .then(response => resolve(response.data))
                    .catch(error => console.log(error))
                    .finally(() => context.commit('PRELOADER', false))
            })
        },
        
        updateCondominium(context, params){
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.put(`${URL_BASE}${RESOURCE}/${params.id}`, params)
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        loadCondominium(context, id) {
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        countCondominium(context) {
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${RESOURCE}`)
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        checkNewCondominium(context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${RESOURCE}`, {params})
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        destroyCondominium(context, id){
            
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.delete(`${URL_BASE}${RESOURCE}/${id}`)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
                    //.finally(() => context.commit('PRELOADER', false))    
            })
            
        },

    },

    getters: {

    }

}