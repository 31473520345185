<template>
    <div>
        <v-list>
            <v-list-item>
                <v-row>
                    <v-col class="text-center" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <v-avatar
                            class="mt-4"
                            color="#BDBDBD"
                            size="100"
                        >
                            <v-img 
                                v-if="me.uploads && me.uploads.length > 0"
                                :src="me.uploads[0] | urlUpload"
                                :lazy-src="me.uploads[0] | urlUpload"
                            >
                                <template v-slot:placeholder>
                                    <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                    >
                                    <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                    ></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                            <v-icon size="80" v-else dark>
                                mdi-account-circle-outline
                            </v-icon>
                        </v-avatar>                     
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <v-list-item-content>
                            <v-list-item-title class="text-h7">
                                {{ me.name }} 
                            </v-list-item-title>
                            <v-list-item-subtitle class="caption">
                                {{ me.email }}
                            </v-list-item-subtitle>
                            <div class="mt-2 text-center">    
                                <router-link :to="{ 
                                    name: 'admin.users.index', 
                                    params: {
                                        id: me.id                                
                                    } 
                                }">
                                    <v-chip
                                        label
                                        small
                                        color="blue lighten-5" 
                                        class="ml-3"
                                    >
                                        <span style="font-size:10px">EDITAR</span>
                                    </v-chip>
                                </router-link>
                            </div>                                
                        </v-list-item-content>
                    </v-col>
                </v-row> 
            </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list
            nav
            dense
        >
            <v-list-item-group
                v-model="selectedItem"
                color="primary"
            >
            <span v-for="(item, i) in single_items"
                    :key="i">

                <router-link 
                    :to="{
                        name: item.link,
                        params: item.params 
                    }" 
                    class="nav-link"
                >                    
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item-content>
                        
                    </v-list-item>
                </router-link>
            </span>


                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-login-variant</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title @click="logout()">Sair</v-list-item-title>
                    </v-list-item-content>
                    
                </v-list-item>

            </v-list-item-group>
        </v-list>

    </div>
</template>

<script>
export default {
    created() {
        this.checkMenu()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    data () {
        return {
            selectedItem: '',
            single_items: [
                {id:0, text: 'Inicial', icon: 'mdi-home', link: 'admin.dashboard', params:{} },
                {id:7, text: 'Facs', icon: 'mdi-face-agent', link: 'admin.facs.index', params:{ multiple_status:['Nova','Em atendimento','Desatualizada','Cliente em espera','Troca de atendimento','Reatendimento']} },
                {id:1, text: 'Imóveis', icon: 'mdi-home-city', link: 'admin.properties.index', params:{ multiple_status:['Ativo','Desatualizado']} },
                {id:2, text: 'Condomínios', icon: 'mdi-office-building', link: 'admin.condominiums.index', params:{} },
                {id:3, text: 'Construtoras', icon: 'mdi-tools', link: 'admin.builders.index', params:{} },
                {id:8, text: 'Propostas', icon: 'mdi-file-sign', link: 'admin.proposals.index', params:{} },
                {id:7, text: 'Solicitações', icon: 'mdi-comment-question-outline', link: 'admin.userrequests.index', params:{} },
                {id:4, text: 'Usuários', icon: 'mdi-account', link: 'admin.users.index', params:{} },
                {id:5, text: 'Unidades', icon: 'mdi-source-branch', link: 'admin.branches.index', params:{} },
                {id:6, text: 'Empresa', icon: 'mdi-bank-outline', link: 'admin.tenants.index', params:{} }
            ],
        }
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },
    },

    watch: {
        me () { 
            this.checkMenu()
            /*  
            if(this.me.id){
                this.checkMenu()
            }else{
                this.logout()
            } 
            */
        },
    },

    methods: {
        logout () {            
            this.$store.dispatch('logout')
        },

        checkMenu(){
            if(this.me.profile && this.me.profile != 'Master'){
                const menu = []

                this.single_items.forEach(i => {
                    if(i.id != 4 && i.id != 5 && i.id != 6){
                        menu.push(i)
                    }   
                })

                this.single_items = menu
            }
        },

    },

}
</script>

<style >
.nav-link {
    text-decoration: none;
    color: black;
}

.bg-active {
  background-color: #E3F2FD;
}

</style>